import "../styles/about.scss"
import React from "react"
import Reactmarkdown from "react-markdown"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import { graphql } from "gatsby"
import { MemberCard } from "../components/member-card/member-card"

interface Props {
  data: any
}

const AboutPage: React.FC<Props> = ({ data }) => {
  const aboutData = data?.strapiAbout

  return (
    <Layout>
      <div id="page-container">
        <SEO title={aboutData.title} />
        <h1>{aboutData.title}</h1>
        <Reactmarkdown
          source={aboutData.body}
          transformImageUri={uri =>
            uri.startsWith("http") ? uri : `${process.env.GATSBY_MEDIA_BASE_URL}${uri}`
          }
        />
        <section className="team-members">
          {aboutData.teamMembers.map(member => (
            <MemberCard key={member.id} member={member} />
          ))}
        </section>
      </div>
    </Layout>
  )
}

export default AboutPage

export const ABOUT_PAGE_QUERY = graphql`
  query {
    strapiAbout {
      title
      slug
      body
      values {
        title
        body
      }
      teamMembers {
        id
        name
        role
        about
        avatar {
          publicURL
          childImageSharp {
            fluid(maxHeight: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
