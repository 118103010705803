import React from "react"
import { Image } from "../image/image"

interface Props {
  member: {
    id: string
    name: string
    role: string
    about: string
    avatar: {
      publicURL: string
      childImageSharp: any
    }
  }
}

export const MemberCard: React.FC<Props> = ({ member }) => {
  return (
    <div className="member">
      <Image image={member.avatar} imageAlt={`${member.name}_${member.role}`} objectPosition={"top"} />
      <section className="member-meta">
        <h3>{member.name}</h3>
        <h4>{member.role}</h4>
      </section>
    </div>
  )
}
